/* eslint-disable consistent-return */
import { Button, ButtonGroup, Icon } from '@shopify/polaris';
import { ChevronRightMinor, ChevronLeftMinor } from '@shopify/polaris-icons';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { StyledCarousel } from './styled';

interface CarouselProps {
  slides: React.ReactNode[] | JSX.Element[];
  themeColor?: string;
  autoPlay?: boolean;
  interval?: number;
  footerType?: 'button' | 'dots';
  textAlignPaginator?: 'center' | 'left' | 'right';
  isOpen?: boolean;
}

const Carousel: FC<CarouselProps> = ({
  slides,
  themeColor = '#cccccc',
  autoPlay = true,
  interval = 5000,
  footerType = 'dots',
  textAlignPaginator = 'center',
  isOpen = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);
  const autoPlayRef = useRef<any>();

  const goToSlide = useCallback((index: number) => {
    console.log('go to slide');
    setCurrentIndex(index);
  }, []);

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  }, [slides.length]);

  const intervalRef = useRef<number>(0);
  const handleButtonNav = (direction: 'prev' | 'next') => {
    const isPrev = direction === 'prev';
    const isAtStart = currentIndex === 0;
    const isAtEnd = currentIndex === slides.length - 1;

    // eslint-disable-next-line no-nested-ternary
    const index = isPrev ? (isAtEnd ? 0 : currentIndex + 1) : isAtStart ? slides.length - 1 : currentIndex - 1;
    goToSlide(index);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    autoPlayRef.current = goToNextSlide;

    // If autoPlay is enabled, set up the interval
    if (autoPlay) {
      const play = () => {
        autoPlayRef.current();
      };

      intervalRef.current = setInterval(play, interval) as unknown as number;
      return () => clearInterval(intervalRef.current);
    }
  }, [autoPlay, goToNextSlide, interval]);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0);
    }
  }, [slides, isOpen]);

  return (
    <StyledCarousel $themeColor={themeColor} $textAlignPaginator={textAlignPaginator}>
      <div className="carousel-inner" ref={slideRef} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            {slide}
          </div>
        ))}
      </div>
      <div className="carousel-pagination">
        {footerType === 'dots' ? (
          slides.map((_, index) => (
            <button
              type="button"
              key={index}
              className={`carousel-pagination-bullet${currentIndex === index ? ' active' : ''}`}
              onClick={() => goToSlide(index)}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))
        ) : (
          <ButtonGroup variant="segmented">
            <Button icon={<Icon source={ChevronLeftMinor} tone="base" />} onClick={() => handleButtonNav('prev')} />
            <Button icon={<Icon source={ChevronRightMinor} tone="base" />} onClick={() => handleButtonNav('next')} />
          </ButtonGroup>
        )}
      </div>
    </StyledCarousel>
  );
};

export default memo(Carousel);
