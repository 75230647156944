export const OT_BASE_URL = {
  RD_DOCS: 'https://synctrack.io/knowledge-base/order-tracking/',
};

export const LINK = {
  CUSTOMER_SHOP: (shop: string) => `https://${shop}/apps/${process.env.REACT_APP_PROXY_PATH}`,
  SHOPIFY_ADMIN: (shop: string) => `https://${shop}/admin`,
  HELP_CENTER: OT_BASE_URL.RD_DOCS,
  EDD_DOCS: `${OT_BASE_URL.RD_DOCS}features/estimated-delivery-date`,
  BLACKLISTING_DOCS: `${OT_BASE_URL.RD_DOCS}features/blacklisting`,
  SLACK_COMMUNITY: 'https://join.slack.com/t/omega-ordertracking/shared_invite/zt-1t8tcni7a-_kFvqify0I0KPVS7qrtXVg',
  RETURNS_DRIVE_APP: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=ot&utm_medium=homeinapp',
  SYNC_TRACK_APP: `https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=ot&utm_medium=homeinapp`,
  ORDER_TRACKING_APP: (shop: string) => `https://${shop}/apps/order-tracking`,
  SHOPIFY_MENU: (shop: string) => `https://${shop}/admin/menus`,
  ORDER_DETAIL: (shop: string, id: string | number) => `https://${shop}/admin/orders/${id}`,
  TRACKING_ORDER_NOW: (shop: string, trackingNo: string) =>
    `https://${shop}/apps/${process.env.REACT_APP_PROXY_PATH || 'order-tracking'}?tracking_number=${trackingNo}`,
  EDD_VIDEO: 'https://www.youtube.com/watch?v=o_GFAjR4yv8&t=289s',
  OMG_NOTI_LINK: 'https://help.omegatheme.com/en/article/how-to-connect-email-account-l8lplu/',
  INTEGRATE_AVADA: 'https://help.omegatheme.com/en/article/how-to-integrate-with-avada-to-send-notifications-5gto4/',
  GET_AVADA_APP: 'https://apps.shopify.com/avada-email-marketing?surface_detail=omega_order_tracking',
  INTEGRATE_PAGE_FLY: 'https://help.omegatheme.com/en/',
  GET_PAGE_FLY_APP: 'https://pagefly.link/zJFjjWun',
  OT_VER_2: `${OT_BASE_URL.RD_DOCS}omega-order-tracking-version-2.0`,
  CHAT_PLUGIN: (stamp: number) => `https://apps.synctrack.io/helpdesk/plugin.js?appId=38&v=${stamp}`,
  CHECKOUT_SETTINGS: (shop: string) => `https://admin.shopify.com/store/${shop}/settings/checkout`,
  TRACKING_LINK_VIDEO: 'https://www.youtube.com/embed/B5cJ-Az3sTs?si=OKFLwTs4AI27xuq6',
  OUR_SITE: 'https://trackordernow.com/',
  BLOCKIFY_APP: 'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=ot&utm_medium=homeinapp',
  RETURN_DRIVE_START_APP: 'https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/home/cross_sell_return_drives_img.png',
  RETURN_DRIVE_START_BACKGROUND:
    'https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/home/return_drive_start_background.png',
  RETURN_DRIVE_LOGO: 'https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/logo/returns_drive.png',
  RETURN_DRIVE_LINK_CROSS:
    'https://apps.shopify.com/omega-returns-drive?utm_campaign=api_inte&utm_source=ot&utm_medium=homeinapp',
  LINK_BFCM: `https://www.omegatheme.com/bfcm-deals/?utm_campaign=bfcm&utm_source=blockify&utm_medium=inappbanner`,
  THUMBNAIL_BFCM: 'https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/etc/bfcm-thumbnail.webp',
  GROWAVE_LINK:
    'https://apps.shopify.com/growave?show_store_picker=1&utm_campaign=synctrack&utm_medium=in-app&utm_source=partner',
  GROWAVE_APP: 'https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/home/browave_logo.webp',
};
