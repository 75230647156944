export const crossSellAppConfig = [
  {
    title: 'Streamline returns to reduce time, costs, retain revenue, and drive customer loyalty',
    des: 'Synctrack Returns boosts customer experience with customizable return portal. Integrates seamlessly with Shopify to manage all returns in one dashboard.',
    link: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=ot&utm_medium=hometop',
    impressEvent: 'impress_returnsdrive',
    clickEvent: 'click_returnsdrive',
  },
  {
    title: 'Protect your store from PayPal/Stripe hold and disputes. Get funds in 3 days',
    des: 'Synctrack PayPal Tracking Sync is an official PayPal partner. Synctrack keeps your business transparent, saves time & avoids PayPal holds.',
    link: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=ot&utm_medium=hometop',
    impressEvent: 'impress_synctrack',
    clickEvent: 'click_synctrack',
  },
  {
    title: 'Reduce shipping support tickets, boost sales & customer trust',
    des: 'Estimated Delivery Date offer clear shipping details and setting accurate delivery expectations. Display shipping date can increase conversion rates by 20-25%.',
    link: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=ot&utm_medium=hometop',
    impressEvent: 'impress_estimated',
    clickEvent: 'click_estimated',
  },
  {
    title: 'Validate customer information and address',
    des: 'Avoids invalid shipping addresses and prevents less valuable orders. Add COD fee, shipping discounts. Hide payment & shipping options on checkout page.',
    link: 'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=ot&utm_medium=hometop',
    impressEvent: 'impress_checkout',
    clickEvent: 'click_checkout',
  },
];
