import { Button, Icon, InlineGrid, Tooltip } from '@shopify/polaris';
import { StyledSubscriptionPlans } from './styled';
import Layout from '@/components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserPlanQuery, useLazyGetUserPlanQuery, useUpdatePlanMutation } from '@/redux/api/api.caller';
import { useEffect, useMemo, useState } from 'react';
import { AccountPlan, Subscription } from '@/constants/enum';
import commonSlice from '@/redux/features/common.slice';
import { handleToastMutation } from '@/helpers/toast';
import DiscountCode from './components/DiscountCode';
import PlanCard from './components/PlanCard';
import { getSubscriptionPlans } from './config';
import { DiscountsMajor } from '@shopify/polaris-icons';
import ChristmasModal from './components/ChristmasModal';
import ChristmasBanner from './components/ChristmasBanner';
import { DiscountType } from '@/types/account';
import dayjs from 'dayjs';
import planSlice, { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
// import CrossSellReturnBanner from '@/components/CrossSellReturnBanner';

const SubscriptionPlans = () => {
  const [isOpenModal, setOpenModal] = useState<{ discount: boolean; christmas: boolean }>({
    discount: false,
    christmas: false,
  });
  const [subscription, setSubscription] = useState<Subscription>(Subscription.Monthly);
  const [planSelected, setPlanSelected] = useState<AccountPlan>();
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const christmasBanner = useSelector(christmasBannerSelector);
  const [getUserPlan, { isLoading: isFirstLoading }] = useLazyGetUserPlanQuery();
  const [updatePlan, updatePlanStatus] = useUpdatePlanMutation();
  const { data: userPlanData, isLoading } = useGetUserPlanQuery({ screen: 'plan' });

  const handleChangeSubscription = (plan: Subscription) => {
    setSubscription(plan);
  };

  const handleOpenDiscountModal = (type: 'discount' | 'christmas') => setOpenModal((prev) => ({ ...prev, [type]: true }));
  const handleCloseDiscountModal = (type: 'discount' | 'christmas') => setOpenModal((prev) => ({ ...prev, [type]: false }));

  const subscriptionPlansData = useMemo(() => {
    return getSubscriptionPlans().map((item) => ({
      ...item,
      price: {
        monthly: `${userPlanData?.data.monthlyPricing[item.plan]}`,
        annually: `${userPlanData?.data.yearlyPricing[item.plan]}`,
      },
    }));
  }, [userPlanData?.data.monthlyPricing, userPlanData?.data.yearlyPricing]);

  const generateDiscountPlan = (plans: AccountPlan[]) => {
    let result = '';
    if (plans.length < 3) {
      const capitalizedWords = plans.map((plan) => plan.charAt(0).toUpperCase() + plan.slice(1));
      result = capitalizedWords.join(', ');
    } else {
      result = 'all';
    }
    return result;
  };

  const handleUpdatePlan = (plan: AccountPlan) => () => {
    setPlanSelected(plan);
    updatePlan({
      plan,
      subscription: subscription,
    })
      .then((res) => {
        if ('data' in res && res.data.data) {
          window.open(res.data.data.confirmationUrl, 'updatePlan');
        } else {
          dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
          dispatch(
            planSlice.actions.handleChangePlan({
              ...account,
              plan: AccountPlan.Starter,
              subscription: Subscription.Monthly,
              isOldPaidUser: false,
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUserPlan({ screen: 'plan' });
      if (data) {
        setSubscription(data.data.subscription);
      }
    };
    fetchData();
  }, [getUserPlan]);

  return (
    <Layout title="Subscription Plans">
      {/* <CrossSellReturnBanner /> */}
      <ChristmasBanner />

      <StyledSubscriptionPlans>
        <span className="account-subscription-btn">
          {Object.entries(Subscription).map(([key, value]) => (
            <Button
              key={value}
              onClick={() => handleChangeSubscription(value)}
              variant={subscription === value ? 'primary' : 'tertiary'}
            >
              {`${key}${value === Subscription.Annually ? ' (save 20%)' : ''}`}
            </Button>
          ))}
        </span>
        <div className="mt-16">
          <Button onClick={() => handleOpenDiscountModal('discount')} variant="plain">
            Apply your discount code
          </Button>
        </div>
        <div className="discount-code">
          {userPlanData && userPlanData.data && userPlanData.data.discount.length > 0 && 'Applied discount:'}
          {userPlanData &&
            userPlanData.data &&
            (() => {
              const { discount, discountPlanApply } = userPlanData.data;

              return discount.map(({ type, code, value, startTime, expireTime }, index) => {
                const isFeatureType = type === DiscountType.Feature;

                return (
                  <Tooltip
                    key={index}
                    content={
                      isFeatureType ? (
                        <>
                          Validation date is on <strong>{dayjs(startTime).format('MMM DD, YYYY')}</strong>. End date is on{' '}
                          <strong>{dayjs(expireTime).format('MMM DD, YYYY')}</strong>
                        </>
                      ) : (
                        `Enjoy your ${type === DiscountType.Fixed ? '$' : ''}${value}${
                          type === DiscountType.Percent ? '%' : ''
                        } discount for ${generateDiscountPlan(discountPlanApply)} monthly plans when upgrading. `
                      )
                    }
                  >
                    <div className="discount-badge">
                      <Icon source={DiscountsMajor} tone="base" />
                      <span className="discount-text">{code}</span>
                    </div>
                  </Tooltip>
                );
              });
            })()}
        </div>

        <div className="w-100">
          <InlineGrid columns={{ xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }} gap={'400'}>
            {subscriptionPlansData.map((item) => (
              <PlanCard
                key={item.plan}
                {...item}
                subscription={subscription}
                handleUpdatePlan={handleUpdatePlan(item.plan)}
                isLoading={isLoading || isFirstLoading}
                isBtnLoading={updatePlanStatus.isLoading && planSelected === item.plan}
                isActivePlan={
                  (userPlanData?.data.plan === item.plan && subscription === userPlanData?.data.subscription) ||
                  (userPlanData?.data.plan === AccountPlan.Starter && item.plan === AccountPlan.Starter)
                }
              />
            ))}
          </InlineGrid>
        </div>
      </StyledSubscriptionPlans>

      <DiscountCode
        isOpen={isOpenModal.discount}
        onClose={() => handleCloseDiscountModal('discount')}
        onOpenConfirmModal={() => handleOpenDiscountModal('christmas')}
      />
      <ChristmasModal
        isOpen={isOpenModal.christmas}
        onClose={() => handleCloseDiscountModal('christmas')}
        start={christmasBanner.startDate}
        end={christmasBanner.endDate}
      />
    </Layout>
  );
};

export default SubscriptionPlans;
