import styled from 'styled-components';

export const StyledCrossSellSlide = styled.div<{
  $isOpen: boolean;
}>`
  .Polaris-Banner {
    width: 100%;
  }

  .Polaris-ButtonGroup {
    &__Item > button {
      background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, 0.15) 100%), rgba(48, 48, 48, 1);
      color: rgba(255, 255, 255, 1);
      box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, 0.8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset,
        0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, 0.25) inset;
    }
  }

  transition: all 0.5s;
  overflow-y: hidden;
  padding-bottom: ${({ $isOpen }) => ($isOpen ? '1rem' : '0')};
  max-height: ${({ $isOpen }) => ($isOpen ? '15rem' : 0)};
`;
