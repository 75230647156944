import { CustomBanner } from '@/components';
import { ClosedBanners } from '@/constants/closedBanners';
import dashboardPageSlice, { haveClosedConstantFeatureBannerSelector } from '@/redux/features/dashboard.slice';
import { useDispatch, useSelector } from 'react-redux';

const FeatureBanner = () => {
  const dispatch = useDispatch();
  const haveClosedFeatureBanner = useSelector(haveClosedConstantFeatureBannerSelector);

  const handleCloseBanner = () => {
    dispatch(dashboardPageSlice.actions.handleCloseConstantFeatureBanner(ClosedBanners.LunarNewYearHoliday));
  };

  return (
    <CustomBanner
      isClose={haveClosedFeatureBanner[ClosedBanners.LunarNewYearHoliday]}
      maxHeight="20rem"
      tone="info"
      title="Lunar New Year Holiday Notice"
      onDismiss={handleCloseBanner}
    >
      Our team will be out of the office for the Lunar New Year from <b>January 25th</b> to <b>February 2nd, 2025</b>. During this
      time, there may be delays in our responses. We expect to reply to your messages by <b>Monday, February 3rd</b>, when we
      return. For limited support, our customer support agents will be available from <b>January 25th</b> to <b>February 2nd</b>.
      Thank you for your understanding! <b>Happy Lunar New Year!</b> 🎉
    </CustomBanner>
  );
};

export default FeatureBanner;
