import { Carousel } from '@/components';
import { ClosedBanners } from '@/constants/closedBanners';
import dashboardPageSlice, { haveClosedConstantFeatureBannerSelector } from '@/redux/features/dashboard.slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crossSellAppConfig } from './config';
import { Banner } from '@shopify/polaris';
import { StyledCrossSellSlide } from './styled';
import { useSendGa4AdminAnalyticsMutation } from '@/redux/api/api.caller';
import { Environment } from '@/constants';

const CrossSellSlide = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const haveCloseSlide = useSelector(haveClosedConstantFeatureBannerSelector);
  const isCloseScrollSell = haveCloseSlide[ClosedBanners.CrossSellAppFeature];
  const dispatch = useDispatch();

  const [sendGa4Analytics] = useSendGa4AdminAnalyticsMutation();

  const handleTry = (link: string, clickEvent: string) => {
    if (process.env.REACT_APP_ENV === Environment.Production && clickEvent) {
      sendGa4Analytics({ eventName: clickEvent });
    }
    window.open(link, '_blank');
  };

  const handleDismiss = () => {
    dispatch(dashboardPageSlice.actions.handleCloseConstantFeatureBanner(ClosedBanners.CrossSellAppFeature));
    setOpen(false);
  };

  useEffect(() => {
    let timeoutId: any = null;
    if (!isCloseScrollSell && !isOpen) {
      timeoutId = setTimeout(() => {
        setOpen(true);
        window.scrollTo(0, 0);
        if (process.env.REACT_APP_ENV === Environment.Production) {
          crossSellAppConfig.map((item) => item.impressEvent).forEach((event) => sendGa4Analytics({ eventName: event }));
        }
      }, 3000);
    }
    if (isCloseScrollSell && isOpen) {
      setOpen(false);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseScrollSell]);

  return (
    <StyledCrossSellSlide $isOpen={isOpen}>
      <Carousel
        isOpen={isOpen}
        slides={crossSellAppConfig.map(({ title, des, link, clickEvent }, index) => (
          <Banner
            key={index}
            tone="info"
            title={title}
            action={{
              content: 'Try for free',
              onAction: () => handleTry(link, clickEvent),
            }}
            onDismiss={handleDismiss}
          >
            {des}
          </Banner>
        ))}
        interval={10000}
        themeColor="rgba(0,0,0,0.5)"
        textAlignPaginator="right"
      />
    </StyledCrossSellSlide>
  );
};

export default CrossSellSlide;
