import styled from 'styled-components';

export const StyledCarousel = styled.div<{
  $themeColor: string;
  $textAlignPaginator: string;
}>`
  position: relative;
  overflow: hidden;

  .carousel-inner {
    display: flex;
    transition: transform 0.3s ease-out;
  }

  .slide {
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .carousel-pagination {
    text-align: ${({ $textAlignPaginator }) => $textAlignPaginator};
    padding-top: 0.25rem;
  }

  .carousel-pagination-bullet {
    display: inline-block;
    border: none;
    background-color: #ddd;
    margin: 0 5px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;

    &.active {
      background-color: ${({ $themeColor }) => $themeColor};
    }
  }
`;
